import { SET_ALL_USERS, SET_SEARCH_FILTER } from "../../constants";

const initialState = {
    users: [],
    searchFilter: "",
}

export default (state = initialState, action) => {
    switch (action.type){
        case SET_ALL_USERS:
            return setAllUsers(state, action.payload);
        case SET_SEARCH_FILTER:
            return setSearchFilter(state, action.payload);
        default:
            return state;
    }
}

const setAllUsers = (state, data) => {
    return {
        ...state,
        users: data
    };
}

const setSearchFilter = (state, data) => {
    return {
        ...state,
        searchFilter: data
    }
}