import {SET_AGED_TRIAL_BALNCE_SORT, SET_ALL_CLIENTS_WITH_INVOICES} from "../../constants";

const initialState = {
    clients: {
        sort: {
            col: { id: 1 },
            type: "desc"
        },
        list: []
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_CLIENTS_WITH_INVOICES:
            return setAllCientsWithInvoices(state, action.payload);
        case SET_AGED_TRIAL_BALNCE_SORT:
            return setAgedTrialBalanceSort(state, action.payload);
        default:
            return state;
    }
}

const setAllCientsWithInvoices = (state, data) => {
    return {
        ...state,
        clients: {
            ...state.clients,
            list: data.clients
        }
    }
}


const setAgedTrialBalanceSort = (state, sort) => {
    return {
        ...state,
        clients: {
            ...state.clients,
            sort: sort
        }
    }
}